import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const Premium = () => {
    const updateUserData = async (user) => {
		console.log("Updating user data..."); 
        const apiUrl = 'https://c8f7wc43g1.execute-api.us-east-1.amazonaws.com/dev/dbtest';  // Replace with your actual API endpoint
						
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    dbtest: user.userId,  // Assuming the user's sub as the partition key
                    info: "Updated info",  // Example payload; adjust as needed
                })
            });

            const data = await response.json();
            console.log('Success:', data);
            alert('User data updated successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to update user data.');
        }
    };





	return (
		<Authenticator>
			{({ signOut, user }) => {
					console.log(user);
					return (
				<div>
					<h1>PREMIUM Hello, world!</h1>
					<pre>{JSON.stringify(user, null, 2)}</pre>
					<p>Welcome, {user?.signInDetails?.loginId ?? 'Loading...'}!</p>
					<button onClick={() => updateUserData(user)}>Update My Data</button>
					<button onClick={signOut}>Sign Out</button>
				</div>
				);
			}}
		</Authenticator>	
	);
};

export default Premium;