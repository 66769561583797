import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Home from './Home';
import Premium from './Premium';
import Database from './Database';

// import React from 'react';
// import { withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';

function App() {
  return (
	<div>
		<Router>
			<Routes>
				<Route path="/" element={<Premium />} />
				<Route path="/login" element={<Premium />} />
				<Route path="/home" element={<Home />} />
				<Route path="/premium" element={<Premium />} />
				<Route path="/database" element={<Database />} />
			</Routes>
		</Router>
	</div>
  );
}

export default App;
