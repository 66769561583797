import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

import { createRoot } from 'react-dom/client';
const root = createRoot(document.getElementById('root'));
root.render(<App />);
