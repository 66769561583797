import React, { useState, useEffect, FormEvent } from "react";
import { Amplify } from 'aws-amplify';
import { generateClient } from "aws-amplify/api";
import { listTodos } from './graphql/queries';
import { createTodo, deleteTodo } from './graphql/mutations';

import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);
const client = generateClient();

import { Button, Flex, Heading, Text, TextField, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

const Database = () => {
	function handleError(error) {
		console.error('Error occurred:', error.message);
		console.error('Complete error object:', error);
		if (error.errors) {
			console.error('GraphQL Errors:', error.errors);
		}
	}
    const [todos, setTodos] = useState([]);

    useEffect(() => {
        fetchTodos().catch(handleError);
    }, []);

    async function fetchTodos() {
        try {
            const apiData = await client.graphql({ query: listTodos });
            const todosFromAPI = apiData.data.listTodos.items;
            setTodos(todosFromAPI);
        } catch (error) {
            handleError(error);
        }
    }

    async function handleCreateTodo(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const form = event.currentTarget;
        const formData = new FormData(form);
		const name = formData.get('name');
		const description = formData.get('description');
        try {
            await client.graphql({
                query: createTodo,
                variables: { input: { name, description } }
            });
            await fetchTodos();
            form.reset();
        } catch (error) {
            handleError(error);
        }
    }

    async function handleDeleteTodo(id: string) {
        try {
            await client.graphql({
                query: deleteTodo,
                variables: { input: { id } }
            });
            await fetchTodos();
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <View className="Database">
            <Heading level={1}>My Todo App</Heading>
            <form onSubmit={handleCreateTodo}>
                <TextField name="name" placeholder="Todo Name" required />
                <TextField name="description" placeholder="Todo Description" required />
                <Button type="submit">Create Todo</Button>
            </form>
            <Flex direction="column" gap="small">
                {todos.map(todo => (
                    <Flex key={todo.id} justifyContent="space-between">
                        <Text>{todo.name}: {todo.description}</Text>
                        <Button onClick={() => handleDeleteTodo(todo.id)}>Delete</Button>
                    </Flex>
                ))}
            </Flex>
        </View>
    );
};

export default Database;
